@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'DM Sans', sans-serif;
    scroll-behavior: 'smooth';
  }
  .app ,html, body{
    font-family: 'DM Sans', sans-serif;
    scroll-behavior: 'smooth' !important;
  }
  
  @layer base {
  html {
    scroll-behavior: smooth;
  }
}