
    .bg-mobile {
      background-image: url('./assets/3d shieldsmall.png');
      background-repeat: no-repeat;
      background-position: center;
    }

  

    .bg-desktop {
      background-image: url('./assets/3d Shield.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

.app ,html, body{
    font-family: 'DM Sans', sans-serif;
    scroll-behavior: 'smooth' !important;
  }